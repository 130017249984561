import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../services/AxiosInstance";
import { loadingToggleAction } from "../../store/actions/AuthActions";
// image

import loginbg from "../../images/pic1.png";

function Register(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  let errorsObj = {
    email: "",
    address: "",
    password: "",
    fname: "",
    lname: "",
    confrimpassword: "",
    jobcatory: "",
    // carehome_id: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const [confrimpassword, setconfrimPassword] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [address, setAddress] = useState("");
  const [type, setJobcatory] = useState("");
  // const [carehome_id, setCarehome_id] = useState("");
  const [, setImage] = useState("");
  const [checkemail, setcheckemail] = useState("");
  const onChange = (setState) => (e) => {
    setState(e.target.value);
    console.log(setState, e, "setState");
  };
  const dispatch = useDispatch();

  function onSignUp() {
    let error = false;
    const errorObj = { ...errorsObj };
    if (fname === "") {
      errorObj.fname = "First Name is Required";
      error = true;
    }
    if (lname === "") {
      errorObj.lname = "Last Name is Required";
      error = true;
    }
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (address === "") {
      errorObj.address = "Address is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    if (confrimpassword === "") {
      errorObj.confrimpassword = "ConfirmPassword is Required";
      error = true;
    }
    if (confrimpassword !== password) {
      errorObj.confrimpassword = "ConfirmPassword Mismatch!";
      error = true;
    }
    if (type === "") {
      errorObj.jobcatory = "Select Job Category";
      error = true;
    }
    // if (carehome_id === "") {
    //   errorObj.carehome_id = "Select Care Homes";
    //   error = true;
    // }
    if (checkemail === 1) {
      alert("error");
    }
    setErrors(errorObj);
    if (error) return;
    dispatch(loadingToggleAction(true));

    const data = {
      // carehome_id: carehome_id.value,
      fname: fname,
      lname: lname,
      type: type,
      password: password,
      email: email,
    };
    localStorage.setItem("data", JSON.stringify(data));
    navigate("/packages");
  }
  const Jobtype = [
    "RN",
    "CNA",
    "HHA",
    "Amade Ilave",
    "Nutncionista",
    "Trabajadora Social",
  ];
  // const [options, setOptions] = useState([]);
  // const Carehome_id = () => {
  //   axiosInstance
  //     .get("carehomes")
  //     .then((e) => {
  //       const Data = e.data.data;
  //       console.log(e, Data, "Response");
  //       if (e.data.status === true) {
  //         setOptions(Data);
  //       }
  //     })
  //     .catch((e) => console.log(e));
  // };
  const accessemail = () => {
    const formdata = new FormData();
    formdata.append("email", email);
    axiosInstance
      .post("professional_by_email", formdata)
      .then((e) => {
        const Data = e.data;
        console.log(e, Data, "Response");
        if (Data === 1) {
          setcheckemail("This Email is already Exists");
        } else {
          onSignUp();
        }
      })
      .catch((e) => console.log(e));
  };
  // useEffect(() => {
  //   Carehome_id();
  // }, []);
  // const finalOptions = options?.map((e) => ({
  //   value: e.id,
  //   label: e.director,
  // }));
  // console.log(checkemail, "check");
  return (
    <div style={{ backgroundImage: "url(" + loginbg + ")", backgroundSize: "cover", height: "100vh" }}>

      <div className="container">
        <div className="row">
          <div className="offset-md-3 col-md-6 ">
            <div className="authincation-content bg-transparent">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="p-5">
                    <h4 className="text-center mb-4 text-white">Sign up your account</h4>
                    <div className="row">
                      <div className="form-group col-md-6 mb-3">
                        <label className="mb-2 text-white">
                          <strong>First Name</strong>
                        </label>
                        <input
                          value={fname}
                          onChange={onChange(setFname)}
                          type="text"
                          className="form-control"
                        />
                        {errors.fname && (
                          <div className="text-danger">{errors.fname}</div>
                        )}
                      </div>
                      <div className="form-group  col-md-6 mb-3">
                        <label className="mb-1 text-white">
                          <strong>Last Name</strong>
                        </label>
                        <input
                          value={lname}
                          onChange={onChange(setLname)}
                          className="form-control"
                          type="text"
                        />
                        {errors.lname && (
                          <div className="text-danger">{errors.lname}</div>
                        )}
                      </div>
                      <div className="form-group col-md-12 mb-3">
                        <label className="mb-1 text-white">
                          <strong>Email</strong>
                        </label>
                        <input
                          onChange={onChange(setEmail)}
                          value={email}
                          className="form-control"
                          placeholder="hello@example.com"
                          type="email"
                        />
                        {errors.email && (
                          <div className="text-danger">{errors.email}</div>
                        )}
                        {checkemail && (
                          <div className="text-danger">{checkemail}</div>
                        )}
                      </div>
                      <div className="form-group col-md-12 mb-3">
                        <label className="mb-1 text-white">
                          <strong>Address</strong>
                        </label>
                        <input
                          onChange={onChange(setAddress)}
                          value={address}
                          className="form-control"
                          type="text"
                        />
                        {errors.address && (
                          <div className="text-danger">{errors.address}</div>
                        )}
                      </div>
                      {/* <div className="form-group col-md-12 mb-3">
                      <label className="mb-1 text-white">
                        <strong>Care Homes</strong>
                      </label>
                      <Select
                        // defaultValue={selectedOption}
                        onChange={setCarehome_id}
                        options={finalOptions}
                        isSearchable={true}
                      />
                      {errors.carehome_id && (
                        <div className="text-danger">{errors.carehome_id}</div>
                      )}
                    </div> */}
                      <div className="form-group col-md-6 mb-3">
                        <label className="mb-1 text-white">
                          <strong>Professional Type</strong>
                        </label>
                        <select
                          onChange={onChange(setJobcatory)}
                          value={type}
                          className="form-control"
                          defaultValue="Confirm Password"
                        >
                          <option selected hidden>
                            Select Your Professional Type...
                          </option>
                          {Jobtype.map((e) => (
                            <option value={e}>{e}</option>
                          ))}
                        </select>
                        {errors.jobcatory && (
                          <div className="text-danger">{errors.jobcatory}</div>
                        )}
                      </div>
                      <div className="form-group col-md-6 mb-3">
                        <label className="mb-1 text-white">
                          <strong>Profile Picture</strong>
                        </label>
                        <input
                          onChange={(e) => setImage(e.target.files)}
                          className="form-control"
                          type="file"
                        />
                        {/* {errors.jobcatory && (
                          <div className="text-danger">{errors.jobcatory}</div>
                        )} */}
                      </div>
                      <div className="form-group col-md-6 mb-3">
                        <label className="mb-1 text-white ">
                          <strong>Password</strong>
                        </label>
                        <input
                          onChange={onChange(setPassword)}
                          value={password}
                          className="form-control"
                          defaultValue="Password"
                          type="password"
                        />
                        {errors.password && (
                          <div className="text-danger">{errors.password}</div>
                        )}
                      </div>

                      <div className="form-group col-md-6 mb-3">
                        <label className="mb-1 text-white">
                          <strong>Confirm Password</strong>
                        </label>
                        <input
                          onChange={onChange(setconfrimPassword)}
                          value={confrimpassword}
                          className="form-control"
                          defaultValue="Confirm Password"
                          type="password"
                        />
                        {errors.confrimpassword && (
                          <div className="text-danger">
                            {errors.confrimpassword}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="text-center mt-4">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        onClick={accessemail}
                      >
                        Sign me up
                      </button>
                    </div>
                    <div className="new-account mt-3">
                      <p className="text-white">
                        Already have an account?{" "}
                        <Link className="text-primary" to="/login">
                          <strong>  Sign in</strong>
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Register);

import axios from "axios";
import { toast } from "react-toastify";

export const axiosInstance = axios.create({
  baseURL: "https://api.avanzando.dev-iuh.xyz/api/",
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("userDetails");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const Data = error?.response?.data;
    const errors = Data.error;
    if (errors) {
      errors.map((e) => toast.error(e));
    } else {
      toast.error(Data.message);
    }
    return Promise.reject(error);
  }
);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
/// Scroll

/// Image
import Pusher from "pusher-js";
import {
  Dropdown,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import profile from "../../../images/avatar/pic1.jpg";
import LogoutPage from "./Logout";
import { useTranslation } from "react-i18next";
const Header = ({ onNote }) => {
  const [showData, setShowData] = useState(false);
  const onShowData = () => setShowData(true);
  const onHideData = () => setShowData(false);
  const [status, setStatus] = useState(false);
  const path = window.location.pathname;
  const user = useSelector((state) => state.auth.auth);
  const openVideoCallWindow = (token) => {
    try {
      const videoCallWindow = window.open(
        `https://avanzandojuntos.net/join_meeting?token=${token}`,
        "_blank",
        "width=800, height=600"
      );
      if (videoCallWindow) {
        // Popup opened successfully
        toast.success("Video call window opened successfully");
      } else {
        // Popup blocked, open in a new tab
        window.location.href = `https://avanzandojuntos.net/join_meeting?token=${token}`;
      }
    } catch (error) {
      toast.error("Error opening video call window:", error);
    }
  };
  useEffect(() => {
    const pusher = new Pusher("a735ad8627d543e8039e", {
      cluster: "ap2",
      //   encrypted: true,
    });

    const channel = pusher.subscribe(
      `professional-receive-message-${user.localId}`
    );
    console.log("Subscribe done");
    channel.bind(`user-send-message`, (data) => {
      if (path === "/Chat") {
        console.log(data);
      } else {
        toast.success("New Message Has been Arrived !!!");
        setStatus(true);
        console.log(data, "notification");
      }
    });
    return () => {
      pusher.unsubscribe(`professional-receive-message-${user.localId}`);
    };
  }, [path]);

  useEffect(() => {
    const pusher = new Pusher("a735ad8627d543e8039e", {
      cluster: "ap2",
      //   encrypted: true,
    });

    const channel = pusher.subscribe(
      `professional-receive-call-${user.localId}`
    );
    channel.bind(`user-dial-call`, (data) => {
      console.log(data, "message sent");
      openVideoCallWindow(data?.meeting_id);
    });
    return () => {
      pusher.unsubscribe(`professional-receive-call-${user.localId}`);
    };
  }, []);
  const isStatus = () => setStatus(false);
  const { i18n } = useTranslation();
  const changeLanguage = () => {
    const currentLanguage = i18n.language;
    const newLanguage = currentLanguage === "es" ? "en" : "es";
    i18n.changeLanguage(newLanguage);
  };
  const { t } = useTranslation();

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left"></div>
            <ul className="navbar-nav header-right main-notification">
              <li className="nav-item">
                <button
                  className="btn btn-primary text-capitalize "
                  onClick={onShowData}
                >
                  {t("tutorials_video")}
                </button>
              </li>
              <li className="nav-item dropdown notification_dropdown">
                <Link
                  to={"/Chat"}
                  className="nav-link bell bell-link i-false c-pointer ai-icon"
                  onClick={isStatus}
                >
                  <svg
                    width="24"
                    height="22"
                    viewBox="0 0 24 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M23.6667 5.16666C23.6667 2.5895 21.5772 0.5 19 0.5C15.1162 0.5 8.88387 0.5 5.00004 0.5C2.42287 0.5 0.333374 2.5895 0.333374 5.16666V20.3333C0.333374 20.8058 0.618046 21.2305 1.05321 21.4113C1.48955 21.5922 1.99121 21.4918 2.32487 21.1582C2.32487 21.1582 4.59287 18.8902 5.9672 17.517C6.4047 17.0795 6.99739 16.8333 7.61689 16.8333H19C21.5772 16.8333 23.6667 14.7438 23.6667 12.1667V5.16666ZM21.3334 5.16666C21.3334 3.87866 20.2892 2.83333 19 2.83333C15.1162 2.83333 8.88387 2.83333 5.00004 2.83333C3.71204 2.83333 2.66671 3.87866 2.66671 5.16666V17.517L4.31638 15.8673C5.19138 14.9923 6.37905 14.5 7.61689 14.5H19C20.2892 14.5 21.3334 13.4558 21.3334 12.1667V5.16666ZM6.16671 12.1667H15.5C16.144 12.1667 16.6667 11.644 16.6667 11C16.6667 10.356 16.144 9.83333 15.5 9.83333H6.16671C5.52271 9.83333 5.00004 10.356 5.00004 11C5.00004 11.644 5.52271 12.1667 6.16671 12.1667ZM6.16671 7.5H17.8334C18.4774 7.5 19 6.97733 19 6.33333C19 5.68933 18.4774 5.16666 17.8334 5.16666H6.16671C5.52271 5.16666 5.00004 5.68933 5.00004 6.33333C5.00004 6.97733 5.52271 7.5 6.16671 7.5Z"
                      fill="#1362FC"
                    />
                  </svg>
                  {status ? <div className="pulse-css"></div> : null}
                </Link>
              </li>
              <li className="nav-item dropdown notification_dropdown">
                <button className="btn" onClick={changeLanguage}>
                  {i18n.language === "en" ? (
                    <img
                      src="/flag/portugal.png"
                      width="20"
                      height="20"
                      alt="es"
                      title={`Change language to ${
                        i18n.language === "en" ? "Spainsh" : "English"
                      }`}
                    />
                  ) : (
                    <img
                      src="/flag/united-states.png"
                      width="20"
                      height="20"
                      alt="en"
                      title={`Change language to ${
                        i18n.language === "es" ? "English" : "Spainsh"
                      }`}
                    />
                  )}
                </button>
              </li>
              {user?.is_featured === 0 &&
              user?.is_Featured_Paid_Status === "paid" ? (
                <li className="nav-item dropdown notification_dropdown">
                  <div className="nav-link bell bell-link i-false c-pointer ai-icon">
                    pending approval by Admin <i className="fa fa-clock" />
                  </div>
                </li>
              ) : (
                <li className="nav-item dropdown notification_dropdown">
                  <Link
                    to={"/FeaturesPackages"}
                    className={`${
                      user.is_featured === 0
                        ? "nav-link bell bell-link i-false c-pointer ai-icon"
                        : "btn btn-warning"
                    }`}
                  >
                    <i className="fa fa-star" />
                  </Link>
                </li>
              )}

              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link i-false c-pointer"
                >
                  <img
                    src={user.image !== "" ? user.image : profile}
                    width={20}
                    alt=""
                  />
                  <div className="header-info ms-3">
                    <span>{user?.fname}</span>
                    <small>Professional</small>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu
                  align="right"
                  className="mt-3 dropdown-menu dropdown-menu-end"
                >
                  <Link to="/app-profile" className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="ms-2">Profile </span>
                  </Link>

                  <LogoutPage />
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
      <Modal show={showData} onHide={() => setShowData(false)} size="lg">
        <ModalHeader>
          <h4 className="mt-3 text-capitalize">{t("tutorials_video")}</h4>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              <video
                src="/Avanzando_professional.mp4"
                controls
                className="w-100"
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="btn btn-outline-danger" onClick={onHideData}>
            Close
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Header;

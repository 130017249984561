/* eslint-disable react-hooks/exhaustive-deps */
import Pusher from "pusher-js";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Arrow from "../../../Arrow.jsx";
import avatar from "../../../images/avatar/1.jpg";
import { axiosInstance } from "../../../services/AxiosInstance";
import "./Chat.css";
import { useSelector } from "react-redux";

const Chat = () => {
  const [loader, setLoader] = useState(false);
  const [isSend, setIssend] = useState(false);
  const [isname, setIsname] = useState({ name: "", email: "", img: "" });
  const [sideusers, setSideUsers] = useState([]);
  const [showChat, sethidechat] = useState(false);
  const [message, setMessage] = useState("");
  const [showmessage, setShowmessage] = useState([]);
  const [con_Id, setCon_ID] = useState("");
  const [sending, setsending] = useState(false);
  const chattoglle = () => {
    sethidechat(true);
  };
  const user = useSelector((state) => state.auth.auth);

  const getMessage = (id) => {
    setLoader(true);
    axiosInstance
      .get(`professional/conversations/${id !== undefined ? id : con_Id}`)
      .then((response) => {
        console.log(response, "Inbox message");
        if (response.data.status === true) {
          setLoader(false);
          setShowmessage(response.data.data);
        } else {
          setLoader(false);
        }
      })
      .catch((errors) => setLoader(false));
  };
  const isPusher = () => {
    const pusher = new Pusher("a735ad8627d543e8039e", {
      cluster: "ap2",
      //   encrypted: true,
    });

    const channel = pusher.subscribe(
      `professional-send-message-${user.localId}`
    );
    channel.bind(`user-receive-message`, (data) => {
      console.log(data, "message sent");
      getMessage();
    });
    return () => {
      pusher.unsubscribe(`professional-send-message-${user.localId}`);
    };
  };
  useEffect(() => {
    const pusher = new Pusher("a735ad8627d543e8039e", {
      cluster: "ap2",
      //   encrypted: true,
    });

    const channel = pusher.subscribe(
      `professional-receive-message-${user.localId}`
    );
    console.log("Subscribe done");
    channel.bind(`user-send-message`, (data) => {
      console.log(data, "form inbox professional recive");
      getMessage(localStorage.getItem("particapati_id"));
    });
    return () => {
      pusher.unsubscribe(`professional-receive-message-${user.localId}`);
    };
  }, []);
  const Inbox = () => {
    setLoader(true);
    axiosInstance
      .get("professional/conversations")
      .then((response) => {
        console.log(response.data, "sider");
        if (response.data.status === true) {
          setSideUsers(response?.data?.data);
          setLoader(false);
        } else {
          toast.error(response.data.error);
          setLoader(false);
        }
      })
      .catch((errors) => {
        setLoader(false);
        console.log(errors);
      });
  };

  const Sendmessage = () => {
    setsending(true);

    const formdata = new FormData();
    formdata.append("participant_id", con_Id);
    formdata.append("sender_type", "professional");
    formdata.append("body", message);

    axiosInstance
      .post("professional/conversations", formdata)
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          isPusher();
          getMessage();
          setMessage("");
          setsending(false);
        } else {
          setsending(false);

          toast.error("Unknown error occur");
        }
      })
      .catch((errors) => {
        console.log(errors);
        setsending(false);
      });
  };
  const UpdateStatus = (id) => {
    setLoader(true);
    axiosInstance
      .post(
        `professional/conversations/update/${
          id !== undefined ? id : con_Id
        }/user`
      )
      .then((response) => {
        console.log(response);
        Inbox();
        setLoader(false);
      })
      .catch((errors) => {
        console.log(errors);
        setLoader(false);
      });
  };
  useEffect(() => {
    Inbox();
    // StoreParticipants();
    if (isSend === true) {
      getMessage();
      setTimeout(() => {
        setIssend(false);
      }, 2000);
    }
  }, [isSend]);
  console.log(isSend, "");
  return (
    <>
      {loader ? <div className="loader"></div> : null}
      {/* <Header sendingCheck={setIssend} /> */}
      <section className="message-area">
        <div className="container-fuild">
          <div className="row" style={{ height: "100vh" }}>
            <div className="col-12">
              <div className="chat-area">
                {/* <!-- chatlist --> */}
                <div className="chatlist">
                  <div className="modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="chat-header">
                        <div className="msg-search">
                          <input
                            type="search"
                            className="form-control w-100"
                            id="inlineFormInputGroup"
                            placeholder="Search"
                            aria-label="search"
                          />
                        </div>
                      </div>

                      <div className="modal-body">
                        {/* <!-- chat-list --> */}
                        <div className="chat-lists pt-2">
                          <div className="tab-content" id="myTabContent">
                            <div
                              className="tab-pane fade show active"
                              id="Open"
                              role="tabpanel"
                              aria-labelledby="Open-tab"
                            >
                              {/* <!-- chat-list --> */}
                              <div className="chat-list">
                                {sideusers?.map((e) => {
                                  return (
                                    <>
                                      <Link
                                        to={`#`}
                                        className="d-flex align-items-center "
                                        onClick={() => {
                                          setShowmessage([]);

                                          setIsname({
                                            name: e.user.fname + e.user.lname,
                                            email: e.user.email,
                                            img:
                                              e.user.image === null
                                                ? avatar
                                                : e.user.image,
                                          });
                                          chattoglle();

                                          localStorage.setItem(
                                            "particapati_id",
                                            e.conversations[0].participant_id
                                          );
                                          getMessage(
                                            e.conversations[0].participant_id
                                          );
                                          setCon_ID(
                                            e.conversations[0].participant_id
                                          );
                                          UpdateStatus(
                                            e.conversations[0].participant_id
                                          );
                                        }}
                                      >
                                        <div className="flex-shrink-0">
                                          <img
                                            className="img-fluid"
                                            src={
                                              e.user.image === null
                                                ? avatar
                                                : e.user.image
                                            }
                                            alt="userimg"
                                            style={{ borderRadius: "50%" }}
                                            height={50}
                                            width={50}
                                          />
                                          <span className="active"></span>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                          <h3>
                                            {e.user.fname + e.user.lname}
                                            {e.conversations.map((x, index) =>
                                              x.sender_type === "user" &&
                                              index ===
                                                e.conversations.length - 1 &&
                                              x.status === 0 ? (
                                                <span className="badge bg-success">
                                                  New
                                                </span>
                                              ) : null
                                            )}
                                          </h3>
                                          <p
                                            className="d-inline-block text-truncate"
                                            title={e.user.email}
                                          >
                                            {e.user.email}
                                          </p>
                                        </div>
                                      </Link>
                                    </>
                                  );
                                })}
                              </div>
                              {/* <!-- chat-list --> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- chatlist --> */}

                {/* <!-- chatbox --> */}
                <div className={showChat ? " chatbox-2 showbox" : "showbox"}>
                  <div class="modal-dialog-scrollable">
                    <div class="modal-content">
                      <div class="msg-head">
                        <div className="container">
                          <div class="row">
                            <div class="d-flex justify-content-between align-items-center">
                              <div class="d-flex align-items-center">
                                <span
                                  className="chat-icon"
                                  onClick={() => sethidechat(false)}
                                >
                                  <Arrow />
                                </span>

                                <img
                                  className="img-fluid rounded-circle"
                                  src={isname.img}
                                  alt="user img"
                                  height={45}
                                  width={45}
                                />

                                <div className="ms-1">
                                  <h3>{isname.name}</h3>
                                  <p>{isname.email}</p>
                                </div>
                              </div>
                              <div>
                                <a
                                  target="_blank"
                                  href={`https://avanzandojuntos.net/createMeeting/${con_Id}/${user?.role}`}
                                  rel="noreferrer"
                                >
                                  <i class="fa fa-video fs-3" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="modal-body">
                        <div className="msg-body px-3">
                          <ul>
                            {showmessage.map((e) => {
                              const createdAt = e.created_at;
                              const date = new Date(createdAt);
                              const formattedTime = date.toLocaleString(
                                "en-US",
                                {
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                }
                              );

                              console.log(formattedTime);

                              return (
                                <>
                                  {e.sender_type === "professional" ? (
                                    <li className="repaly">
                                      <p> {e.body}</p>
                                      <span className="time">
                                        {formattedTime}
                                      </span>
                                    </li>
                                  ) : (
                                    <li className="sender">
                                      <p> {e.body}</p>
                                      <span className="time">
                                        {formattedTime}
                                      </span>
                                    </li>
                                  )}
                                </>
                              );
                            })}
                          </ul>
                        </div>
                      </div>

                      <div className="send-box">
                        <form action="">
                          <input
                            type="text"
                            className="form-control"
                            aria-label="message…"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Write message…"
                          />

                          <button disabled={sending} onClick={Sendmessage}>
                            <i aria-hidden="true"></i> Send{" "}
                            {sending ? (
                              <i className="fa fa-spinner fa-spin fs-3"></i>
                            ) : (
                              <i className="fa fa-paper-plane fs-3"></i>
                            )}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer/> */}
    </>
  );
};

export default Chat;
